.no-border {
  border: none !important;
}

.progressUser {
  background: #ffffff;
  border-radius: 10px;
  margin: 2%;
  padding: 1.5%;
  height: 100%;
  min-height: 75vh;
}

.myChart {
  /* Ajustes adicionales para el gráfico */
  display: flex;
  padding: 20px;
  
}

/* Otros estilos adicionales que necesites */

.hidden {
  display: none;
}

.spinner {
  font-size: 50px;
  color: #333;
}

.oc-mask {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
