.wompi {
  display: flex;
  padding: 0%;
  justify-content: center;
  margin-top: 10px;
  margin-block-end: auto;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2c700;
    width: 200px;
    font-weight: 600;
    height: 50px;
    color: #19418c;
    border-radius: 3rem;
    .logo {
      width: 100px;
      margin-left: -5px;
      margin-top: 5px;
    }
  }
}

.wompiCampaign {
  display: flex;
  padding: 0%;
  justify-content: center;
  margin-top: 10px;
  margin-block-end: auto;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2c700;
    width: auto;
    font-weight: 600;
    height: auto;
    padding: 2px 4px;
    font-size: 13px;
    color: #19418c;
    border-radius: 3rem;
    .logo {
      width: 50px;
      margin-left: -5px;
      margin-top: 5px;
    }
  }
}

